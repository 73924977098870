<template>
  <v-app>
    <v-navigation-drawer class="" v-model="drawer" app>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <router-link :to="item.to">
            <v-icon>{{ item.icon }}</v-icon>
            <span>{{ item.title }}</span>
          </router-link>
        </v-list-item>
        <v-list-item v-if="session?.authenticated" @click="logout">
            <v-icon>mdi-account</v-icon>
            <span>Logout</span>
        </v-list-item>
        <v-list-item v-else>
          <router-link to="/login">
            <v-icon>mdi-account</v-icon>
            <span>Login</span>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Costa Del Sol - Bar Channel Application</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>


<script setup lang="ts">
import {type Session} from "~/src/model/interfaces"
import type {Ref} from "vue";

const status = "unauthenticated"
const drawer = ref(false)
const session: Ref<Session|null|undefined> = useState('session')

const items = ref([
  { title: 'Home', icon: 'mdi-home', to: '/' },
  { title: 'Map', icon: 'mdi-map', to: '/map' },
  { title: 'Tide', icon: 'mdi-waves', to: '/tidepage' },
//  { title: 'Register', icon: 'mdi-account-plus', to: '/register' },
//  { title: 'Login', icon: 'mdi-login', to: '/login' }
])

// function navigate(item) {
//   // You can handle navigation here, for instance, using Vue Router
//   console.log('Navigating to:', item.title);
// }

onMounted(() => {
  $fetch('/v1/auth/session').then((res: any) => {
    session.value = res.response as Session
  })
})

function logout() {
  console.log('logout called by user')
  if (session.value?.authenticated) {
    $fetch('/v1/auth/logout').then((res: any) => {
      if (res.status == 'success') {
        session.value!!.authenticated = false
      }
    })
  }
}
</script>

<style scoped>
.left-sidebar-layout {
  display: flex;
  height: 100%;
  width: 100%;
}

.main-content {
}

.sidebar {
  width: 100px;
}

.top-bar {
  height: 30px;
}

.main-content {
  flex: 1;
}

.leftButtons {
  flex-direction: column;
}
</style>
